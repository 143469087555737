@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#root {
  font-family: "Manrope", sans-serif;
  margin-left: 2vw;
  margin-right: 2vw;
}

.pop-up-type-1 {
  border-radius: 1rem !important;
  font-family: "Manrope", sans-serif !important;
  width: fit-content !important;
  min-width: 35em !important;
  max-width: 50em !important;
}

.pop-up-type-1-title {
  border-radius: 0.95rem 0.95rem 0 0 !important;
  background: #7b99e6 !important;
  color: white !important;
  font-size: 1.2rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 0.5rem !important;
  font-weight: 700 !important;
}

.pop-up-type-1-close:focus {
  box-shadow: none !important;
}

@media screen and (min-width: 993px) {
  .hide-on-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 426px) {
  .hide-on-medium {
    display: none !important;
  }
}

@media screen and (max-width: 425px) {
  #root {
    margin-left: 0;
    margin-right: 0;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .pop-up-type-1 {
    min-width: 50vw !important;
  }
}
