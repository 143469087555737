.future-order-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unreg-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.unreg-email-col {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.us_email_label {
  font-size: 1.2rem;
}

.unreg-hint {
  width: 50% !important;
  text-align: center;
  margin-top: 0.5rem;
}

.unreg-file-field {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .unreg-file-field {
    width: 100%;
  }

  .unreg-hint {
    width: 80% !important;
  }
}
