@media screen and (min-width: 768px) {
  .faq-page-container {
    width: 60% !important;
  }
}

.faq-section-title {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.accordion:nth-child(1) > .faq-section-title {
  margin-top: 0rem;
}
