.order-card {
  background: #ffffff;
  border: 1px solid #6c72f1;
  border-radius: 12px;
  margin-bottom: var(--bs-gutter-x);
}

.order-card-title {
  display: flex;
  border-bottom: 1px solid #6c72f1;
  padding: 0.5rem;
  height: 3rem;
  align-items: center;
}

.order-card-title div {
  font-size: 0.9rem;
  padding-left: 0.5rem;
  width: max-content;
}

.order-card-title img {
  width: 2rem;
}

.order-card-content-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.order-card-content-row div:first-child {
  font-size: 0.9rem;
  color: #6c72f1;
  text-align: left;
}

.order-card-content-row div:last-child {
  font-size: 0.9rem;
  color: #8b8b8b;
  text-align: right;
}

.order-card-content-row div img {
  padding-left: 0.5rem;
}

.order-card-content-row {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.order-card-content-row:last-child {
  padding-bottom: 0.6rem;
}

.order-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-card-button-row {
  background-color: #6c72f1;
  border-radius: 0 0 10px 10px;
}

.order-card-button {
  width: 100%;
  height: 100%;
  padding: 0.7rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  background-color: #6c72f1;
  border: none;
  border-radius: 0 0 10px 10px;
}

.Queuedup-color,
.Active-color {
  color: #f89500 !important;
}

.Completed-color {
  color: #329c00 !important;
}

.Declined-color {
  color: #e20000 !important;
}

.Paused-color {
  color: #8b8b8b !important;
}

.details-modal-popup {
  border-radius: 1rem !important;
  border: 1px solid #6c72f1 !important;
  font-family: "Manrope", sans-serif !important;
  width: fit-content !important;
  min-width: 20em !important;
  max-width: 50em !important;
}

.details-modal-title {
  color: #6c72f1 !important;
  font-weight: 500 !important ;
  font-size: 1.2rem !important;
  padding-bottom: 0.2rem !important;
}

.details-modal-value {
  color: #8f8d8e;
  width: fit-content !important;
  padding: 0 !important;
  font-weight: 400;
  font-size: 1.1rem;
  border-bottom: 1px solid #6c72f1;
  text-align: left;
  max-height: 6.5em;
  min-height: max-content;
  overflow-y: auto;
  white-space: pre-wrap;
}

.single-detail {
  padding-bottom: 1.2rem;
}

.details-modal-top-title {
  color: #6c72f1;
  font-weight: 700;
  font-size: 1.2rem;
}
