.order-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-radio label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.order-radio input[type="radio"] + .order-radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  position: relative;
  margin-right: 0.5rem;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.order-radio input[type="radio"]:checked + .order-radio-label:before {
  background-color: #6c72f1;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.order-radio input[type="radio"]:focus + .order-radio-label:before {
  outline: none;
  border-color: #6c72f1;
}
.order-radio input[type="radio"]:disabled + .order-radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.order-radio input[type="radio"] + .order-radio-label:empty:before {
  margin-right: 0;
}

@media screen and (max-width: 425px) {
  .order-radio label {
    font-size: 0.8rem;
  }
}
