.blog-page-title-container {
  min-height: 50rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.blog-page-title-col,
.blog-page-title-caption-col,
.blog-page-title-img-col,
.blog-page-title-arrow-down-col {
  display: flex;
  justify-content: center;
}

.blog-page-title {
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  width: 75% !important;
  display: flex;
  justify-content: center;
}

.blog-page-title-caption {
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  width: 40% !important;
  display: flex;
  justify-content: center;
}

.blog-page-title-img-col img {
  width: 15em;
}

.blog-arrrow-down {
  color: #6c72f1;
  font-size: 1.3rem;
}

.blog-page-content-container {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-preview-card {
  background-color: var(--bg) !important;
  flex-direction: column;
  transition: all 0.4s;
}

.blog-preview-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.blog-preview-card .blog-post-preview-text {
  -webkit-line-clamp: 7;
  -moz-line-clamp: 7;
  -ms-line-clamp: 7;
  line-clamp: 7;
}

.no-link-decoration {
  text-decoration: none !important;
}
