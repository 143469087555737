.user-settings-email-row {
  border-bottom: 1px solid #e0e0e0;
}

.user-settings-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100em;
  background-color: #d77be6;
  color: white;
  font-weight: 300;
  font-size: 2.4rem;
  height: 4.5rem;
  width: 4.5rem;
}

.user-settings-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.5em !important;
  padding-bottom: 1.5em;
}

.user-settings-email span:first-child {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.user-settings-email span:last-child {
  font-weight: 300;
  color: #e0e0e0;
  font-size: 1.1rem;
}

.reset-pass-inputs {
  margin-top: 2em !important;
  display: flex;
  justify-content: center;
}

.reset-pass-inputs input {
  width: 17em;
  margin: 0.5rem;
}

.pass-reset-button {
  background-color: #6c72f1;
  color: white;
  border: 1px solid #6c72f1;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 0.9rem;
  width: 19em;
  font-weight: 400;
  margin: 2em 0;
  transition: box-shadow 0.3s;
}

.pass-reset-button:hover {
  box-shadow: 0px 8px 16px rgba(0, 123, 255, 0.589);
}

.settings-form-centring-col {
  display: flex;
  justify-content: center;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.axios-loading {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #6c72f1 8%, #848aec 18%, #6c72f1 33%);
  position: relative;
}

@media screen and (max-width: 755px) and (min-width: 426px) {
  .reset-pass-inputs {
    flex-direction: column;
    align-items: center;
  }

  .reset-pass-inputs .input-type-1 {
    width: 100%;
  }

  .user-settings-email span:first-child {
    font-size: 1.1rem !important;
    padding-bottom: 0.2rem;
  }
  .user-settings-email span:last-child {
    font-size: 1rem !important;
  }

  .user-settings-email {
    padding-left: 2rem !important;
  }
}

@media screen and (max-width: 425px) {
  .user-settings-email {
    padding-left: 2rem !important;
  }
  .user-settings-email span:first-child {
    font-size: 1.2rem !important;
    padding-bottom: 0.2rem;
    overflow-y: scroll;
  }
  .user-settings-email span:last-child {
    font-size: 1rem !important;
  }

  .user-settings-email {
    height: 5rem;
    padding-bottom: calc(-1 * var(--bs-gutter-y));
  }

  .reset-pass-inputs {
    display: grid;
    grid-template-columns: 100%;
  }

  .reset-pass-inputs input {
    width: auto;
  }

  .user-settings-email-row {
    padding-bottom: 0.4rem;
  }
}

@media screen and (max-width: 320px) {
  .user-settings-email {
    padding-left: 0rem !important;
  }

  .user-settings-email span:first-child {
    font-size: 0.9rem !important;
  }
  .user-settings-email span:last-child {
    font-size: 0.8rem !important;
  }

  .user-settings-icon {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  .user-settings-email-row {
    align-items: center;
  }

  #root
    > div.service-container.container-fluid
    > div
    > div.margin-rule-for-mobile.col-md-10.col-12
    > div:nth-child(1)
    > div.service-content-itself-row.row
    > div
    > div.user-settings-email-row.row
    > div.col-md-1.col-2 {
    display: flex;
    align-items: center;
  }
}
