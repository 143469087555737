.post-page-title-and-anotation-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  text-align: center;
}

.post-page-title {
  font-size: 3.5rem;
  font-weight: 600;
}

.post-page-anotation {
  font-size: 1.31rem;
  font-weight: 300;
  max-width: 30rem;
}

.post-page-title-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-text-container {
  width: 75%;
  white-space: pre-wrap;
}

.post-text,
.post-list-text,
.post-list-title {
  font-size: 1.2rem;
}

.post-text-title {
  font-size: 1.9rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.single-post-section {
  margin-bottom: 2rem;
}

.post-list-title {
  font-weight: 600;
  margin-right: 0.5rem;
}

.post-list li {
  color: #6c72f1;
}

.post-list li span {
  color: black;
}

.sticky-post-contetn-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 2rem;
}

.sticky-post-contetn-menu-title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  transition: all 0.4s;
}

.sticky-post-contetn-menu-title:hover {
  color: #6c73f1a9;
  cursor: pointer;
}

.active-sticky-post-contetn-menu-title {
  color: #6c72f1;
}
