.service-options-container {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto;
  border-radius: 1em;
  border: 1px solid rgba(0, 123, 255, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
}

.service-types-accordion .tiktokItem:last-of-type .accordion-collapse {
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
}

.service-options-container .accordion-flush .tiktokItem {
  border-bottom-right-radius: 1em !important;
  border-bottom-left-radius: 1em !important;
}

.service-options-title {
  display: flex;
  justify-content: center;
  padding: 0.75em 0 0.75em 0;
  font-size: 1.2em;
  color: white;
  background: #7b99e6;
  margin-right: calc(var(--bs-gutter-x) * 0.5);
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  border-radius: 1rem 1rem 0 0;
  font-weight: 700;
}

.service-options-container .accordion-button {
  padding: 0;
}

.service-network {
  color: black;
  font-weight: 300;
  font-size: 1rem;
}

.service-types-accordion .accordion-body {
  padding: 0 !important;
}

.service-types-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.service-types-accordion .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  transform: none;
}

.service-types-accordion .accordion-button::after {
  background-image: none !important;
}

.service-options-container img {
  width: 1.5rem;
  margin: 0.5rem;
}

.service-types {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  border-radius: 1em;
}

.service-types li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
}

.service-types a {
  color: black;
  text-decoration: none;
  transition: color 0.2s;
}

.service-types .active-service-type {
  color: #6c72f1;
}

.service-types a:focus {
  color: #6c72f1;
}

.service-types a:active {
  color: #6c72f1;
}

.service-types div {
  padding-left: 0 0.5rem;
}

.service-types a:last-child li {
  border-bottom: none;
}

@media screen and (max-width: 992px) and (min-width: 426px) {
  .service-options-container {
    max-width: none !important;
    margin-bottom: 2rem;
  }

  .service-options-main-row {
    width: 100%;
    --bs-gutter-x: 0 !important;
  }

  .order-hint-col img {
    margin-right: 0.5rem;
  }
}

/* @media screen and (max-width: 770px) and (min-width: 426px) {

} */

@media screen and (max-width: 425px) {
  .service-options-container {
    display: none;
  }
}
