.home-page-container {
  margin-top: 5rem;
}

.landing-gif {
  width: 100%;
}

.landing-title {
  font-size: 5rem;
  color: #343a40;
  font-weight: 700;
  line-height: 120%;
}

.home-page-start-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-start-bottom-row {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.landing-start-bottom-row .orders-top-up-button {
  height: 3rem;
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.landing-start-bottom-row a {
  text-decoration: none !important;
}

.landing-start-caption {
  line-height: 150%;
  color: #343a40;
  font-weight: 300;
  font-size: 1.15rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 40rem;
}

.landing-adv-col {
  display: flex;
}

.single-adv {
  display: flex;
  justify-content: flex-end;
}

.single-adv img {
  width: 3rem;
}

.adv-text {
  color: #8f8d8e;
  font-size: 0.7rem;
  max-width: 11rem;
}

.benefits-card {
  height: 15rem;
  width: 100%;
  border-radius: 20px;
  color: white !important;
  display: flex;
  padding: 1rem;
  text-decoration: none !important;
}

.first-benefit-card {
  background: #87b8d9 !important;
}

.benefits-container .benefits-card:last-child {
  background: #ae88cf;
}

.benefits-container {
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.benefits-card-title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 120%;
}

.benefits-card-text {
  font-size: 0.9rem;
}

.benefits-card-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.why-us-title {
  font-size: 3rem;
  font-weight: 400;
}

.why-us-text {
  font-size: 1.1rem;
  font-weight: 300;
}

.how-to-order-container .benefits-card {
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.how-to-order-title {
  font-size: 1.5rem;
}

.how-to-order-content {
  display: flex;
}

.how-to-order-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 13rem;
  margin-right: 2rem;
}

.how-to-order-step img {
  width: 6rem;
}

.how-to-order-step-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.how-to-order-step-text {
  font-size: 0.8rem;
  font-weight: 300;
}

.blog-preview-container {
  margin-bottom: 7rem;
}

.blog-preview-container .benefits-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.first-blog-post-preview {
  background-color: #da8e50ab !important;
  transition: all 0.4s;
}

.second-blog-post-preview {
  background-color: #8899cfb0 !important;
  transition: all 0.4s;
}

.third-blog-post-preview {
  background-color: #cf88b3b2 !important ;
  transition: all 0.4s;
}

.first-blog-post-preview:hover,
.second-blog-post-preview:hover,
.third-blog-post-preview:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.blog-post-preview-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first-blog-post-preview .blog-post-preview-title-itself {
  font-size: 1.8rem;
  font-weight: 700;
}

.first-blog-post-preview .blog-post-preview-title img {
  width: 4rem;
}

.first-blog-post-preview .blog-post-preview-text {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-post-preview-title img {
  width: 3rem;
}

.blog-post-preview-title .blog-post-preview-title-itself {
  font-size: 1.4rem;
  font-weight: 700;
}

.blog-post-preview-text {
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
}

.landing-faq-container button {
  color: #6c72f1;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.2s;
}

.landing-faq-container-title-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landing-faq-container-title {
  font-size: 1.7rem;
}

.landing-faq-container-caption {
  font-size: 0.95rem;
  color: #898a8b;
  margin-bottom: 1rem;
}

.landing-faq-questions-container .accordion-button:focus {
  box-shadow: none !important;
}

.landing-faq-questions-container .accordion-button:not(.collapsed) {
  color: #343a40 !important;
  background-color: white !important;
}

.landing-faq-questions-container .accordion-button {
  border: 1px solid rgba(108, 114, 241, 0.05) !important;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11) !important;
  border-radius: 10px !important;
}

.landing-faq-questions-container .accordion-item {
  border: 1px solid rgba(108, 114, 241, 0.05) !important;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11) !important;
  border-radius: 10px !important;
  margin-bottom: 1rem;
}

.landing-faq-questions-container .accordion-body {
  font-weight: 100 !important;
  line-height: 150% !important;
  color: #343a40 !important;
}

.landing-faq-questions-container {
  margin-bottom: 7rem;
}

.width-fix-for-mid-sized-devices-show {
  display: none;
}

.width-fix-for-mid-sized-devices-hide {
  display: flex;
}

@media screen and (max-width: 1310px) {
  .width-fix-for-mid-sized-devices-show {
    display: flex;
    text-decoration: none !important;
    margin-top: 1rem;
    justify-content: center;
  }

  .width-fix-for-mid-sized-devices-hide {
    display: none;
  }

  .how-to-order-step {
    max-width: 13rem;
    width: 100%;
  }

  .benefits-card-text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1175px) {
  .blog-post-preview-title .blog-post-preview-title-itself {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1084px) {
  .landing-title {
    font-size: 4.5rem;
  }

  .first-benefit-card img,
  .second-benefit-card img {
    width: 10rem;
  }

  .blog-post-preview-title .blog-post-preview-title-itself {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 994px) {
  .home-page-container {
    margin-top: 1rem;
  }

  .landing-title {
    font-size: 4.2rem;
  }

  .landing-start-caption {
    font-size: 1.1rem;
  }

  .benefits-card-title {
    font-size: 1.3rem;
  }

  .how-to-order-step img {
    width: 6rem;
  }
}

@media screen and (max-width: 964px) {
  .benefits-container .second-benefit-card img {
    display: none;
  }

  .landing-title {
    font-size: 3.5rem;
  }

  .landing-start-caption {
    font-size: 0.9rem;
  }

  .how-to-order-step-title {
    font-size: 1rem;
  }

  .how-to-order-step-text {
    font-size: 0.7rem;
  }

  .nav-login-button-col .orders-top-up-button {
    width: 10rem;
  }
}

@media screen and (max-width: 870px) and (min-width: 426px) {
  .how-to-order-content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 2rem;
  }

  .how-to-order-container .benefits-card {
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .how-to-order-container {
    margin-top: 3rem !important;
    margin-bottom: 3rem;
  }

  .how-to-order-step {
    margin-right: 0;
    width: 100%;
  }

  .how-to-order-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .how-to-order-step img {
    width: 8rem;
  }

  .how-to-order-step-title {
    font-size: 1.7rem;
  }

  .how-to-order-step-text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 775px) and (min-width: 426px) {
  .blog-preview-col {
    margin-bottom: 1rem;
  }

  .how-to-order-content {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 2rem;
  }
}

@media screen and (max-width: 805px) and (min-width: 426px) {
  .benefits-container .first-benefit-card img,
  .benefits-container .second-benefit-card img {
    display: none;
  }
}

@media screen and (max-width: 576px) and (min-width: 426px) {
  .second-benefit-card {
    margin-top: 1.5rem;
  }

  .landing-title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 425px) {
  .second-benefit-card img {
    display: flex;
  }

  .width-fix-for-mid-sized-devices-show {
    display: none;
  }

  .width-fix-for-mid-sized-devices-hide {
    display: flex;
  }

  .landing-title {
    font-size: 10.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-page-container * {
    max-width: 100%;
  }

  .home-page-container {
    margin-top: 1rem;
  }

  .landing-start-bottom-row {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
  }

  .landing-start-bottom-row a {
    width: 100%;
  }

  .landing-start-bottom-row .orders-top-up-button {
    margin-top: 6rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .landing-adv-col {
    position: absolute;
    left: 0;
    top: 0;
    animation: bannermove 10s linear infinite;
    transform: translate3d(0, 0, 0);
  }

  @keyframes bannermove {
    100% {
      -webkit-transform: translateX(-178%);
    }
  }

  .single-adv img {
    width: 4rem;
  }

  .adv-text {
    color: #8f8d8e;
    font-size: 0.8rem;
    width: 14rem;
  }

  .single-adv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .benefits-card {
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  .second-benefit-card {
    margin-top: 1.5rem;
  }

  .benefits-card img {
    margin-top: 1rem;
    width: 80%;
  }

  .benefits-card-title {
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
  }

  .benefits-card-text {
    line-height: 150%;
    font-weight: 400;
    font-size: 1rem;
  }

  .how-to-order-content {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .how-to-order-container .benefits-card {
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }

  .how-to-order-container {
    margin-top: 3rem !important;
    margin-bottom: 3rem;
  }

  .how-to-order-step {
    margin-right: 0;
    width: 80%;
  }

  .how-to-order-title {
    text-align: center;
  }

  .how-to-order-step img {
    width: 8rem;
  }

  .how-to-order-step-title {
    font-size: 1.7rem;
  }

  .how-to-order-step-text {
    font-size: 1.2rem;
  }

  .blog-preview-container {
    flex-direction: row;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
    flex-wrap: nowrap !important;
    -ms-overflow-style: none;
  }

  .blog-preview-container::-webkit-scrollbar {
    display: none;
  }

  .first-blog-post-preview {
    width: 80vw !important;
    scroll-snap-align: center;
    flex-shrink: 0;
  }

  .second-blog-post-preview {
    width: 80vw !important;
    scroll-snap-align: center;
    flex-shrink: 0;
  }

  .third-blog-post-preview {
    width: 80vw !important;
    scroll-snap-align: center;
    flex-shrink: 0;
  }

  .blog-preview-col {
    width: 80% !important;
    display: flex;
    justify-content: center;
  }

  .blog-post-preview-title {
    flex-direction: column-reverse;
    height: auto;
    justify-content: flex-end;
  }

  .blog-post-preview-title img {
    width: 6rem !important;
  }

  .blog-post-preview-title-itself {
    font-size: 1.2rem !important;
    text-align: center;
  }

  .blog-post-preview-text {
    font-size: 1rem;
  }

  .landing-faq-container-title,
  .landing-faq-container-caption {
    text-align: center;
  }
}
