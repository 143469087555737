.orders-balance-details-row {
  border-bottom: 1px solid #e0e0e0;
}

.orders-balance-details-col {
  display: flex;
  height: 6em;
  font-family: "Manrope", sans-serif;
  align-items: center;
}

.orders-balance-details-col img {
  height: inherit;
}

.orders-balance-title {
  font-weight: 500;
  font-size: 1.5rem;
}

.orders-balance a {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 400;
  color: #7b99e6;
}

.orders-top-up-button-col {
  display: flex;
  align-items: center;
}

.orders-top-up-button {
  border: solid 1px #7b99e6;
  color: #7b99e6;
  font-weight: 400;
  font-size: 0.8rem;
  border-radius: 2rem;
  background-color: white;
  padding: 0.7rem 3.5rem;
  transition: box-shadow 0.2s;
}

.orders-top-up-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.288);
}

.orders-counters-col {
  display: flex;
  justify-content: flex-end;
}

.orders-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.orders-counter:first-child {
  padding-right: 2rem;
}

.orders-counter-itself {
  font-weight: 500;
  font-size: 1.5rem;
}

.orders-counter-title {
  font-weight: 400;
  font-size: 0.8rem;
}

.service-content-delimiter {
  color: #e0e0e0;
  display: flex;
  justify-self: center;
  align-self: center;
  width: calc(100% + 0.5 * var(--bs-gutter-x));
}

.your-orders-title {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.orders-order-row {
  height: 100%;
}

.orders-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.orders-not-found-title {
  font-weight: 500;
  font-size: 1.4rem;
}

.orders-not-found-content {
  font-weight: 400;
  font-size: 0.8rem;
  width: 15rem;
  color: #343a40;
}

@media screen and (max-width: 425px) {
  .orders-top-up-button-col a {
    text-decoration: none;
  }

  .orders-top-up-button {
    width: 90%;
  }

  .orders-top-up-button-col {
    display: block;
  }

  .orders-top-up-button-col a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .orders-top-up-button-col .orders-top-up-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .orders-balance-title {
    font-size: 1rem;
  }

  .orders-balance a {
    font-size: 1rem;
  }

  .orders-counters-col {
    justify-content: space-between;
    padding-bottom: 0.7rem;
  }

  #root
    > div.service-container.container-fluid
    > div
    > div.margin-rule-for-mobile.col-md-10.col-12
    > div:nth-child(1)
    > div.service-content-itself-row.row
    > div {
    padding-left: 0px;
    padding-right: 0px;
  }
}
