.service-container {
  margin-top: 1rem;
}

.service-content-container {
  border: 1px solid rgba(108, 114, 241, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
  border-radius: 1rem;
  min-height: 31.8em;
  padding: 0 !important;
}

.service-content-itself-row {
  padding: 1.5em 2em;
}

.service-content-link-row {
  border-bottom: 1px solid #7b99e6;
  margin: 0 !important;
}

.service-content-link {
  font-weight: 700;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.4s;
  padding: 0 !important;
}

.service-content-link:not(.active-content-link):hover {
  background-color: #7b99e696;
}

.service-content-link a {
  text-decoration: none;
  color: #e0e0e0;
  transition: color 0.4s;
}

.service-content-link a:hover {
  color: white;
}

.service-content-link div {
  display: flex;
  justify-content: center;
  padding: 0.7em 0 0.7em 0;
}

.service-content-link span {
  text-align: center;
}

.active-content-link {
  background-color: #7b99e6;
}

.active-content-link a {
  color: white;
}

.service-content-link:first-child {
  border-top-left-radius: 1rem;
}

.service-content-link:last-child {
  border-top-right-radius: 1rem;
}

.you-chose-services {
  color: #898a8b;
  font-weight: 300;
  font-size: 0.95rem;
  width: fit-content !important;
}

.you-chose-services-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 992px) and (min-width: 426px) {
  .service-container-main-row {
    flex-direction: column-reverse;
  }

  .service-content-link:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .service-content-link:last-child:not(:first-child) {
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .order-input-title img {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  .order-hint-caption {
    display: none;
  }

  .order-hint-col img {
    display: none;
  }

  .order-input-title img {
    display: inline;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 425px) {
  .service-content-itself-row {
    padding: 1em 0em !important;
  }

  .service-content-itself-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .service-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .margin-rule-for-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .service-content-container {
    box-shadow: none;
    border-radius: 0 !important;
    border: none;
  }

  .service-content-link {
    border-radius: 0 !important;
  }

  .only-one-right-arrow,
  .only-one-left-arrow {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 7vw 13vw 10vw 10vw 10vw 10vw 10vw 10vw 13vw 7vw;
  }

  :is(.only-one-right-arrow, .only-one-left-arrow)
    .router-link-service-caption {
    grid-column-start: 3;
    grid-column-end: 9;
  }

  .router-link-service-image {
    display: flex;
    align-items: center;
  }

  .only-one-right-arrow .router-link-service-image {
    grid-column-start: 9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  .only-one-left-arrow .router-link-service-image {
    grid-column-start: 2;
    grid-column-end: 2;
    /* display: flex;
    justify-content: center; */
  }

  .both-service-arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .both-service-arrows img {
    padding-left: 6.8vw;
    padding-right: 6.7vw;
  }

  .you-chose-services-row {
    margin-bottom: 1rem;
  }
}
