.order-form-row {
  margin-bottom: 2rem;
}

.order-hint-col {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 1.1rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.order-hint-col img {
  height: 2.5rem;
  align-self: flex-start;
}

.order-hint-caption {
  width: 18rem;
}

.order-input-col {
  display: flex;
  flex-direction: column;
}

.order-input-title {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
}

.order-input-title-hint {
  color: #353535;
  font-size: 1.1rem;
}

.input-type-2 {
  background: #ffffff;
  border: 1px solid #353535;
  min-height: 1.5rem;
  border-radius: 0.7rem;
  padding: 0.5rem;
  margin: 0.7em 0 1.5em 0;
  transition: border 0.2s !important;
  width: 100%;
  max-height: 10rem;
  overflow: scroll;
  font-size: 0.8rem;
  font-weight: 300;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #ababab;
  font-size: 0.8rem;
  font-weight: 300;
}

.input-type-2::placeholder {
  color: #ababab;
  font-size: 0.8rem;
  font-weight: 300;
}

.input-type-2:focus {
  border: 1px solid #6c72f1;
}

.order-file-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-file-input-col,
.order-extra-service-input-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.order-file-input {
  position: relative;
  width: 13rem;
  height: 2.3rem;
  transition: box-shadow 0.2s;
  color: #6c72f1;
  box-shadow: 0;
  border-radius: 100rem;
}

.file-selected-input-selected {
  box-shadow: 0;
  transition: box-shadow 0.2s;
  color: #fe4848;
}

.file-selected-input-selected:hover {
  box-shadow: 0px 4px 8px rgba(255, 0, 0, 0.288) !important;
}

.order-file-input:hover {
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.288);
}

.order-file-input > input[type="file"] {
  position: absoulte;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
}

.file-selected {
  border: 1px solid #fe4848 !important;
}

.order-file-input > label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #6c72f1;
  border-radius: 100rem;
  background-color: white;
  line-height: 30px;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem;
  transition: color 0.2s;
}

.order-file-input img {
  height: 1.5rem;
  padding-right: 1rem;
}

.file-input-field-selected-file {
  font-weight: 300;
  font-size: 0.6rem;
  margin-left: 0.6rem;
}

.order-extra-service-input-col img {
  margin-left: 0.5rem;
  height: 1.2rem;
  cursor: pointer;
}

.order-extra-service-caption {
  font-size: 0.8rem;
}

.disabled-file label {
  color: rgba(152, 151, 155, 0.5);
  border: 1px solid rgba(152, 151, 155, 0.5);
  cursor: default;
}

.disabled-file .order-file-input:hover {
  box-shadow: none !important;
}

.disabled-file input[type="file"] {
  cursor: default;
}

.disabled-file img {
  filter: grayscale(1);
  opacity: 0.5;
}

.disabled-input {
  color: rgba(152, 151, 155, 0.212);
  border: 1px solid rgba(152, 151, 155, 0.5);
  cursor: default;
}

.disabled-input::placeholder {
  color: rgba(152, 151, 155, 0.212);
}

.order-form-row input[type="number"]:disabled {
  color: rgba(152, 151, 155, 0.212);
  border: 1px solid rgba(152, 151, 155, 0.5);
}

.file-selected-input {
  color: lightgray;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
}

.file-selected-input::placeholder {
  color: lightgray;
  background-image: url(../../../assets/images/lock.svg);
  filter: grayscale(1);
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: 1.5rem;
}

.file-selected-input img {
  height: 1rem;
  margin-right: 0.5rem;
  filter: grayscale(1);
}

.order-parse-row {
  display: flex;
  margin-bottom: 1rem;
}
.order-parse-row * {
  margin-right: 1rem;
}

.order-input-row {
  display: flex;
  align-items: baseline;
}

.order-input-row .order-input-title {
  margin-right: 0.5rem;
}

.order-input-row .order-extra-service-caption {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.button-type-1 {
  background: #6c72f1;
  border-radius: 0.7rem;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.8rem 4rem;
  color: white !important;
  border: none;
  margin-right: 2rem;
  transition: box-shadow 0.5s;
}

.button-type-1:hover {
  box-shadow: 0px 8px 16px rgba(0, 123, 255, 0.589);
}

.pricing-hint-container .row {
  margin-bottom: 1rem;
  flex-wrap: nowrap !important;
}

.price-gap-selected {
  color: #6c72f1 !important;
}

.pricing-hint-container .col-11 {
  display: flex;
  align-items: flex-start;
  color: black;
  text-decoration: none !important;
}

.views-lang-col {
  justify-content: space-between;
}

.views-lang-single-option {
  display: flex;
  align-items: center;
}

.order-extra-service-without-switch {
  display: flex;
}

@media screen and (max-width: 425px) {
  a[href^="tel"] {
    color: inherit;
    text-decoration: none;
    display: inline;
  }

  .hint-pop-up .swal2-html-container {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .order-file-input {
    width: 14rem;
  }

  .order-hint-col {
    display: none;
  }

  .order-input-title {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .order-input-title img {
    padding-right: 0.6rem;
  }

  .order-form-row {
    margin-bottom: 3rem;
  }

  [contenteditable="true"]:empty:before {
    font-size: 0.85rem;
  }

  .input-type-2 {
    padding: 0.7rem;
  }

  .order-file-input-row {
    flex-direction: column;
  }

  .order-file-input-col {
    width: 100%;
    justify-content: flex-start;
  }

  .order-file-input-col:first-child {
    margin-bottom: 1rem;
  }

  .order-extra-service-input-col {
    display: flex;
    justify-content: space-between;
  }

  .order-parse-row {
    align-items: center;
  }

  .order-parse-row * {
    margin-right: 0.5rem;
  }

  .order-parse-row input {
    font-size: 0.9rem;
  }

  .order-extra-service-without-switch {
    align-items: center;
  }

  .order-extra-service-input-col img {
    margin-left: 0.5rem;
    height: auto;
    cursor: pointer;
  }

  .button-type-1 {
    border-radius: 84px;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0.5rem 2rem;
    margin-right: 1rem;
  }

  .order-create-order-row {
    justify-content: space-between;
  }

  .order-create-order-row
    :is(.order-input-title, .order-extra-service-caption) {
    font-size: 0.8rem;
  }

  .order-double-title-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .order-input-title-hint {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
  }

  .views-lang-col {
    display: grid;
    grid-template-columns: 46% 46%;
    column-gap: 8%;
    row-gap: 0.8rem;
  }

  .views-lang-single-option {
    justify-content: space-between;
  }
}

@media screen and (max-width: 375px) {
  .button-type-1 {
    padding: 0.5rem 1rem;
  }
}
