.balance-input-field {
  display: flex;
  flex-direction: column;
}

.input-type-1 {
  background: #ffffff;
  border: 1px solid rgba(0, 123, 255, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.7em 0 1.5em 0;
  transition: border 0.2s !important;
}

.balance-input-field label {
  font-size: 1rem;
  font-weight: 400;
}

.input-type-1::placeholder {
  color: #e0e0e0;
  font-size: 1rem;
  font-weight: 400;
}

.input-type-1:focus {
  border: 1px solid #6c72f1;
}

.balance-form input:not(#amount)::placeholder {
  letter-spacing: 0.2em;
}

.balance-form .input-type-1:not(#amount) {
  margin-bottom: 0;
}

.balance-promo-field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: rgba(0, 128, 0, 0.432);
}

.error-promo {
  color: rgba(255, 0, 0, 0.514);
}

.balance-form button {
  background-color: #6c72f1;
  color: white;
  border: 1px solid #6c72f1;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 0.9rem;
  width: 100%;
  font-weight: 400;
  transition: box-shadow 0.3s;
}

.balance-form button:hover {
  box-shadow: 0px 8px 16px rgba(0, 123, 255, 0.589);
}

.hint-full {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.hint-text {
  color: #8f8f8f;
  font-size: 0.75rem;
  font-weight: 300;
}

.hint-icon {
  background-color: #8f8f8f;
  font-size: 0.75rem;
  font-weight: 300;
  color: white;
  border-radius: 100em;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
}

.balance-img-col {
  display: flex;
  justify-content: center;
}
