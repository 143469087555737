.tree-container {
  margin-top: 7rem !important;
  margin-bottom: 3rem;
}

.tree-steps {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.tree-steps .div {
  transition: all 10s;
}

.tree-steps .deleted-step {
  display: none;
}

.tree-start-title {
  font-weight: 800;
  display: flex;
  margin: auto;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.progress-bar-container {
  margin: auto;
  width: 100%;
  max-width: 40rem;
  height: 0.7rem;
  background-color: #e0e0e0;
  border-radius: 5rem;
}

.tree-progress-bar {
  height: 100%;
  width: 33%;
  border-radius: 5rem;
  background-color: #6c72f1;
  transition: all 0.2s;
}

.tree-progress-bar-66 {
  width: 66%;
}

.tree-progress-bar-50 {
  width: 50%;
}

.tree-progress-bar-100 {
  width: 100%;
}

.tree-changing-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transition: all 0.2s;
}

.content-change-in-action {
  animation: opacity 0.5s;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tree-first-step-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree-button {
  color: white;
  width: 15rem;
  max-height: 5rem;
  padding: 1rem 3rem;
  border: none;
  border-radius: 0.7rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(153, 163, 198, 0.81);
  position: relative;
  transition: all 0.2s;
}

.tree-button img {
  margin-right: 0.7rem;
  width: 2rem;
}

.tt-tree {
  background: linear-gradient(
    90deg,
    #01d4da -14.81%,
    #4cf1ef -14.8%,
    #ff388d 115.51%
  );
}

.tt-tree:hover {
  box-shadow: -5px 3px 16px #01d4da, 5px 1px 5px #ff388d;
}

.tg-tree {
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
}

.tg-tree:hover {
  box-shadow: -5px 3px 16px #56ccf2, 5px 1px 5px #2f80ed;
}

.tree-folowing-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tree-folowing-questions-title {
  color: #343a40;
  font-size: 1.6rem;
  margin-top: 1.5rem;
}

.tree-folowing-questions-body {
  font-size: 0.9rem;
  color: #898a8b;
  margin-bottom: 1.5rem;
}

.tree-folowing-questions button {
  color: #6c72f1;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.2s;
}

.tree-phase-button {
  background: #ffffff;
  border: 1px solid rgba(108, 114, 241, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
  border-radius: 10px;
  width: 13rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin: 0.3rem;
  text-align: left;
  font-weight: 300;
}

.tree-phase-button img {
  width: 1.9rem;
  margin-right: 0.5rem;
}

.tree-phase-buttons-container {
  display: flex;
  width: 60rem;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 575px) {
  .tree-container {
    margin-top: 1rem !important;
  }

  .tree-first-step-buttons {
    flex-direction: column;
  }

  .tg-tree {
    margin-bottom: 2rem;
  }

  .tree-phase-buttons-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .tree-phase-button {
    width: 95%;
  }

  .tree-phase-button img {
    width: 1.5rem;
  }

  .tree-folowing-questions-body {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tree-phase-button {
    text-align: center;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 375px) {
  .tree-phase-button {
    font-size: 0.7rem;
  }
}
