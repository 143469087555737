.reviews-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.review-card {
  width: 98%;
  height: 10rem;
  background: #ffffff;
  border: 1px solid rgba(0, 123, 255, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
  border-radius: 20px;
  margin-bottom: calc(4% + var(--bs-gutter-x) * 0.5);
  padding: 0.5rem;
}

.review-card-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-card-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review-card-icon {
  font-size: 2rem;
  height: 3.3rem;
  width: 3.3rem;
  background-color: var(--bg) !important;
  margin-right: 1rem;
}

.review-card-name {
  color: #343a40;
  font-weight: 500;
  font-size: 1.1rem;
}

.review-card-service {
  font-weight: 400;
  font-size: 0.9rem;
  color: #343a40;
}

.review-card-link {
  font-weight: 400;
  font-size: 0.9rem;
  color: #7b99e6 !important;
  text-decoration: none !important;
}

.review-card-bottom-row {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  overflow: auto;
  height: 5.4rem;
}

.reviews-page-container .carousel-control-prev-icon {
  background-image: none !important;
}

.review-section-arrow {
  filter: invert(48%) sepia(94%) saturate(2493%) hue-rotate(216deg)
    brightness(95%) contrast(99%);
}

.review-section-arrow:first-child {
  padding-right: 2rem;
}

.review-section-arrow:last-child {
  padding-left: 2rem;
}

.review-carousel-section-indicator {
  background: #6c72f1;
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 10em;
  opacity: 0.3;
  transition: all 0.2s;
  margin-left: 1rem;
  margin-right: 1rem;
}

.acrive-review-chunk {
  opacity: 1;
  width: 3rem;
}

.review-section-controller-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reviews-carousel-item {
  height: 23rem;
}

.write-a-review-card {
  background: #ffffff;
  border: 1px solid rgba(0, 123, 255, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
  border-radius: 20px;
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.review-row {
  width: 100%;
}

.write-a-review-row {
  height: 100%;
}

.write-a-review-title {
  font-size: 1.3rem;
  color: #343a40;
  text-align: center;
}

.write-a-review-card .button-type-1 {
  margin-right: 0 !important;
  text-decoration: none !important;
  margin-top: 1rem;
}

@media screen and (max-width: 1282px) {
  .review-card {
    height: 13rem;
  }

  .review-card-bottom-row {
    height: 7rem;
  }

  .reviews-carousel-item {
    height: 29rem;
  }

  .review-card-icon {
    font-size: 2rem;
    height: 2.5rem;
    width: 2.5rem !important;
    margin-right: 0.3rem;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .review-card-icon {
    display: none;
  }
}

@media screen and (max-width: 861px) {
  .review-card {
    height: 14rem;
  }

  .reviews-carousel-item {
    height: 31rem;
  }

  .write-a-review-card .button-type-1 {
    text-align: center;
    width: 10rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .review-card {
    margin-bottom: 1rem;
  }

  .reviews-page-container {
    margin-top: 2rem;
  }

  .reviews-carousel-item {
    height: 16rem;
  }

  .review-card-bottom-row {
    font-size: 1rem;
  }

  .review-card-name {
    font-size: 1.7rem;
  }

  .review-card-icon {
    margin-right: 0.5rem;
    font-size: 2.5rem;
    height: 3.3rem;
    width: 3.3rem !important;
  }

  .write-a-review-card {
    width: 100%;
    height: 14rem;
    margin: 1rem;
  }
}

@media screen and (max-width: 605px) {
  .review-carousel-section-indicator {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .review-section-arrow:first-child {
    padding-right: 1.5rem;
  }

  .review-section-arrow:last-child {
    padding-left: 1.5rem;
  }

  .review-card-bottom-row {
    height: 8rem;
  }
}

@media screen and (max-width: 425px) {
  .review-carousel-section-indicator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .review-section-arrow:first-child {
    padding-right: 1.5rem;
  }

  .review-section-arrow:last-child {
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 385px) {
  .review-carousel-section-indicator {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }

  .write-a-review-card {
    margin: 0rem;
  }
}

@media screen and (max-width: 365px) {
  .review-card-name {
    max-width: 9rem;
    overflow-y: scroll;
  }
}
