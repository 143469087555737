.contact-banner-container {
  min-height: 10rem !important;
  padding: 2rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  border: 1px solid rgba(108, 114, 241, 0.05);
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.11);
}

.contact-banner-container img {
  height: 6rem;
}

.contact-banner-write-button-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 992px) and (min-width: 426px) {
  .contact-banner-write-button-col {
    justify-content: flex-start;
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 425px) {
  .contact-banner-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .contact-banner-container {
    padding: 0.5rem !important;
    min-height: 7rem !important;
    margin-top: 0rem;
  }

  .contact-banner-container img {
    height: 4.5rem;
    margin-top: 1rem;
  }

  .contact-banner-container .orders-balance-title {
    font-size: 1.1rem !important;
  }

  .contact-banner-container .order-input-title-hint {
    font-size: 0.75rem;
  }

  .contact-banner-container .orders-top-up-button {
    font-size: 0.7rem;
    width: 40%;
    align-self: flex-end;
    text-align: center;
    padding: 0.5rem 1rem;
    margin-right: 25%;
    margin-bottom: 1rem;
  }
}
