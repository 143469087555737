.flipswitch {
  position: relative;
  width: 1.5rem;
  margin-left: 1rem;
}
.flipswitch input[type="checkbox"] {
  display: none;
}
.flipswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid #98979b;
  border-radius: 50px;
}
.flipswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.15s ease-in 0s;
}
.flipswitch-inner:before,
.flipswitch-inner:after {
  float: left;
  width: 50%;
  height: 0.65rem;
  padding: 0;
  line-height: 0.65rem;
  font-size: 0.49rem;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.flipswitch-inner:before {
  content: "";
  padding-left: 0.24rem;
  background-color: #6c72f180;
  color: #6c72f1;
}
.flipswitch-inner:after {
  content: "";
  padding-right: 0.24rem;
  background-color: rgba(152, 151, 155, 0.5);

  color: #6c72f1;
  text-align: right;
}
.flipswitch-switch {
  width: 0.97rem;
  margin: -0.16rem;
  background: #9e9ea1;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.975rem;
  transition: all 0.15s ease-in 0s;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  background: #6c72f1;
}

.flipswitch-cb:checked + .flipswitch-label .flipswitch-inner {
  margin-left: 0;
}
.flipswitch-cb:checked + .flipswitch-label .flipswitch-switch {
  right: 0;
}

.views-lang-single-option .flipswitch-switch {
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 425px) {
  .flipswitch {
    width: 3rem;
  }

  .flipswitch-switch {
    width: 1.5rem;
    height: 1.5rem;
    right: 1.95rem;
    margin: -0.32rem;
  }

  .flipswitch-inner:before,
  .flipswitch-inner:after {
    height: 0.9rem;
    line-height: 0rem;
  }
}
