.error-page-container {
  height: 40rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error-page-container .col:not(.error-page-content) {
  display: flex;
  justify-content: center;
}

.error-page-container img {
  width: 15em;
}

.error-page-title {
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
}

.error-page-content {
  text-align: center;
  font-size: 1.3rem;
}

.error-page-content a {
  text-decoration: none;
  color: #6c72f1;
}
