.order-input-range-field input[type="range"] {
  height: 28px;
  -webkit-appearance: none;
  width: 68%;
}
.order-input-range-field input[type="range"]:focus {
  outline: none;
}
.order-input-range-field input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #6c72f180;
  border-radius: 50px;
  border: 0px solid #010101;
}
.order-input-range-field input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001e;
  height: 22px;
  width: 22px;
  border-radius: 15px;
  background: #6c72f1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.5px;
}
.order-input-range-field
  input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #6c72f180;
}
.order-input-range-field input[type="range"]::-moz-range-track {
  width: 100%;
  height: 11px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #6c72f180;
  border-radius: 50px;
  border: 0px solid #010101;
}
.order-input-range-field input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001e;
  height: 22px;
  width: 22px;
  border-radius: 15px;
  background: #6c72f1;
  cursor: pointer;
}
.order-input-range-field input[type="range"]::-ms-track {
  width: 100%;
  height: 0.65rem;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.order-input-range-field input[type="range"]::-ms-fill-lower {
  background: #6c72f180;
  border: 0px solid #010101;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
.order-input-range-field input[type="range"]::-ms-fill-upper {
  background: #6c72f180;
  border: 0px solid #010101;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #000000;
}
.order-input-range-field input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #00001e;
  height: 22px;
  width: 22px;
  border-radius: 15px;
  background: #6c72f1;
  cursor: pointer;
}
.order-input-range-field input[type="range"]:focus::-ms-fill-lower {
  background: #6c72f180;
}
.order-input-range-field input[type="range"]:focus::-ms-fill-upper {
  background: #6c72f180;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.order-form-row input[type="number"] {
  border: 1px solid #353535;
  border-radius: 3rem;
  width: 5rem;
  height: 1.5rem;
  text-align: center;
}

.order-input-range-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.order-range-input-caption {
  font-size: 1rem;
}

.double-range-input {
  justify-content: flex-start;
}

.double-range-input input {
  margin-right: 0.7rem;
}

.range-input-numer-field {
  display: flex;
}

.range-input-numer-field input {
  margin-right: 0.5rem;
}

@media screen and (max-width: 425px) {
  .order-input-range-field {
    flex-direction: column-reverse;
    align-items: flex-end;
    font-size: 0.9rem;
  }

  .order-input-range-field input[type="range"] {
    width: 100%;
  }

  .order-range-input-caption {
    font-size: 0.9rem;
  }

  .order-parse-row .order-range-input-caption {
    font-size: 0.8rem;
  }

  .double-range-input input[type="range"] {
    margin-right: 0rem;
  }
}
