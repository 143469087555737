.navbar-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-logo-col img {
  width: 100%;
}

.nav-login-button-col {
  display: flex;
  justify-content: flex-end;
}

.nav-login-button-col .button-type-1 {
  font-weight: 400;
  margin-right: 0;
  color: white !important;
}

.navbar-link {
  margin: 1rem;
  text-decoration: none;
  font-size: 1rem;
  color: #343a40;
  border: none;
  background-color: white;
  transition: color 0.2s;
  height: fit-content;
  cursor: pointer;
}

.navbar-link:hover {
  color: #6c72f1;
}

.navbar-link:hover .arrow-down {
  filter: invert(48%) sepia(94%) saturate(2493%) hue-rotate(216deg)
    brightness(95%) contrast(99%);
}

.arrow-down {
  width: 0.6rem;
  margin-left: 0.2rem;
}

.popover-arrow {
  display: none !important;
}

.popover {
  border: 1px solid #007bff !important;
  border-radius: 10px !important;
  max-width: 25rem !important;
  width: 25rem !important;
}

.popover .service-types li {
  border-bottom: none;
  padding-bottom: 0.5rem;
  width: 15rem;
}

.popover-service-network {
  padding-bottom: 0.5rem;
}

.popover img {
  margin-right: 0.5rem;
}

.contact-modal-image {
  width: 5rem;
}

.contact-modal-svg-container {
  height: 8rem;
}

.contact-modal-faq {
  font-size: 2.5rem;
  font-weight: 700;
  color: black;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.contact-modal-popup .col-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.contact-modal-title {
  height: 3.5rem;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 500;
  font-size: 1rem;
  justify-content: center;
}

.contact-modal-popup {
  width: 50rem !important;
  font-family: "Manrope", sans-serif !important;
  border-radius: 1rem !important;
}

.contact-modal-popup .details-modal-top-title {
  margin-bottom: 1rem;
}

.contact-modal-description {
  font-size: 0.8rem;
}

.contact-modal-popup .button-type-1 {
  margin-top: 1rem;
  margin-right: 0;
  margin-bottom: 2rem;
  font-size: 0.8rem;
  color: white !important;
}

.contact-modal-popup .button-type-1 a {
  color: white !important;
  text-decoration: none;
}

.pop-up-type-1 .input-type-1 {
  width: 80%;
}

.pop-up-type-1 .login-modal-input-title {
  margin-left: 10%;
  text-align: left;
  color: #343a40;
  font-size: 1rem;
  font-weight: 500;
}

.pop-up-type-1 .button-type-1 {
  width: 80%;
  margin-right: 0;
  font-weight: 400;
  display: flex !important;
  justify-content: center;
  color: white !important;
}

.swal2-title {
  padding: 0.5em 2em 0.5em !important;
}

.login-redirect-button {
  margin-top: 0.5rem !important;
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #898a8b;
  width: 100%;
}

.login-redirect-button a {
  color: #6c72f1 !important;
}

.nav-login-button-col .orders-top-up-button {
  height: 3rem;
  overflow: hidden;
  padding: 0;
  transition: all 0.2s;
  z-index: 9;
  position: absolute;
  min-width: 15rem;
  align-self: center;
}

@media screen and (min-width: 991px) {
  .nav-login-button-col .orders-top-up-button:hover {
    height: 15rem;
    top: 0;
  }
}

.nav-login-button-col .orders-top-up-button .btn-profile-contaniner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
}

.nav-login-button-col .orders-top-up-button img:first-child {
  width: 1rem;
  margin-right: 0.5rem;
}

.nav-login-button-col .orders-top-up-button img:last-child {
  filter: invert(48%) sepia(94%) saturate(2493%) hue-rotate(216deg)
    brightness(95%) contrast(99%);
  margin-left: 0.5rem;
}

.navbar-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.balance-navbar-link {
  display: flex;
  justify-content: space-between;
}

.balance-navbar-link div:last-child {
  color: #6c72f1;
  margin-left: 1rem;
}

:is(.navbar-links) a,
:is(.navbar-links) button {
  color: #343a40;
  padding-left: 1rem;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.2s;
}

:is(.navbar-links) a:hover,
:is(.navbar-links) button:hover {
  color: #6c72f1;
}

.reset-pass-hint-modal {
  color: #898a8b;
  font-size: 1rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .logged-in-profile-button-container {
    font-size: 1rem;
    justify-content: center !important;
  }

  .navbar-row {
    height: 5.5rem;
  }

  .mobile-navbar-logo-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-navbar-logo {
    height: 4rem;
  }

  .mobile-navbar-top-button {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .pop-up-type-1 .input-type-1 {
    width: 100%;
    height: 4.5rem;
  }

  .pop-up-type-1 .button-type-1 {
    width: 75%;
    font-size: 1.5rem;
    border-radius: 0.7rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .pop-up-type-1 .login-modal-input-title {
    margin-left: 0%;
  }

  .nav__icon {
    display: inline-block;
    position: relative;
    width: 21px;
    height: 1.5px;
    background-color: #6c72f1;
    transition-property: background-color, transform;
    transition-duration: 300ms;
  }
  .nav__icon:before,
  .nav__icon:after {
    content: "";
    display: block;
    width: 21px;
    height: 1.5px;
    position: absolute;
    background: #6c72f1;
    transition-property: margin, transform;
    transition-duration: 300ms;
  }

  .nav__icon:before {
    margin-top: -8px;
  }

  .nav__icon:after {
    margin-top: 8px;
  }

  .nav-logo-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-logo-col .orders-top-up-button {
    height: 2rem;
    overflow: hidden;
    padding: 0;
    transition: all 0.5s;
    position: absolute;
    width: 2rem;
    align-self: center;
    border: none;
    box-shadow: none !important;
  }

  :is(.navbar-links) a,
  :is(.navbar-links) button {
    color: transparent;
    font-size: 1rem;
  }

  .close-mobile-navbar :is(.navbar-links) a,
  :is(.navbar-links) button {
    color: black;
  }

  .close-mobile-navbar {
    height: 13rem !important;
    width: 55% !important;
    left: 3%;
    top: 3%;
    border: solid 1px #7b99e6 !important;
  }

  .close-mobile-navbar .btn-profile-contaniner {
    padding-top: 0 !important;
    padding-bottom: 1rem;
  }

  .close-mobile-navbar .nav__icon::before {
    margin-top: 0;
    transform: rotate(45deg);
  }

  .close-mobile-navbar .nav__icon::after {
    margin-top: 0;
    transform: rotate(-45deg);
  }

  .close-mobile-navbar .nav__icon {
    margin-left: 0.7rem;
    background-color: transparent;
  }

  .nav-logo-col .orders-top-up-button .btn-profile-contaniner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5rem;
    padding-top: 1rem;
  }

  .nav-logo-col .orders-top-up-button img:first-child {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .navbar-links {
    padding-top: 1rem;
  }

  .mobile-navbar-all-services-button .arrow-down {
    width: 1rem;
    filter: none;
    margin-left: 0.5rem;
    transition: transform 0.2s;
  }

  .mobile-services-expanded .arrow-down {
    transform: rotate(180deg);
    filter: invert(48%) sepia(94%) saturate(2493%) hue-rotate(216deg)
      brightness(95%) contrast(99%);
  }

  .mobile-navbar-all-services-button-title {
    color: black;
    transition: all 0.2s;
  }

  .mobile-services-expanded .mobile-navbar-all-services-button-title img {
    filter: invert(48%) sepia(94%) saturate(2493%) hue-rotate(216deg)
      brightness(95%) contrast(99%);
  }

  .mobile-services-expanded .mobile-navbar-all-services-button-title {
    color: #6c72f1 !important;
  }

  .mobile-navbar-all-services-button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 1.5rem;
    overflow: hidden;
    transition: all 0.2s;
  }

  .mobile-services-expanded {
    height: 24rem;
  }

  .mobile-services-expanded-clicked {
    height: 35rem !important;
    z-index: 9;
  }

  .mobile-services-expanded .all-mobile-services-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
  }

  .all-mobile-services-list .service-types li {
    border-bottom: none;
    margin-bottom: 0.5rem;
  }

  .all-mobile-services-list .service-types img {
    width: 1.5rem !important;
  }

  .all-mobile-services-list .service-types a {
    display: flex;
    padding-left: 0 !important;
  }

  .all-mobile-services-list .service-types div {
    font-size: 0.7rem;
  }
  .all-mobile-services-list .popover-service-network img {
    width: 1.5rem !important;
  }

  .logged-in-profile-button {
    min-width: 3rem !important;
    width: 3rem !important;
    border: none;
    box-shadow: none !important;
    transition: all 0.5s !important;
  }

  .mobile-navbar-profile-img {
    width: 1.2rem !important;
  }

  .profile-mobile-expanded {
    border: solid 1px #7b99e6 !important;
    height: 16rem !important;
    width: 55% !important;
    right: 3%;
    top: 3%;
  }

  .logged-in-profile-button .balance-navbar-link div:last-child {
    color: transparent;
  }

  .profile-mobile-expanded .balance-navbar-link div:last-child {
    color: #6c72f1;
    margin-left: 0;
  }

  .logged-in-profile-button .balance-navbar-link {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-mobile-expanded :is(.navbar-links) a,
  :is(.navbar-links) button {
    color: black;
  }
  .profile-mobile-expanded .navbar-links {
    padding-top: 0;
  }

  .profile-mobile-expanded .arrow-down {
    width: 0.8rem;
  }
}

@media screen and (max-width: 375px) {
  .mobile-services-expanded {
    height: 24rem;
  }

  .close-mobile-navbar {
    width: 60% !important;
  }
}

@media screen and (max-width: 320px) {
  .close-mobile-navbar {
    width: 70% !important;
  }

  .logged-in-profile-button-container {
    font-size: 0.8rem;
  }
}
