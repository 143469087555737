.footer-container {
  margin-bottom: 1rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.2rem;
  font-weight: 300;
}

.footer-links .navbar-link {
  font-size: 1.2rem;
  font-weight: 300;
}

.footer-links * {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
}

.copyright-footer {
  margin-bottom: 1rem;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-social-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.footer-social-networks-title {
  font-weight: 100;
}

.footer-social-links img {
  width: 2rem;
  margin-top: 0.5rem;
  margin-right: 2rem;
}

.footer-logo .navbar-link {
  font-size: 1.2rem;
  font-weight: 300;
}

.footer-logo img {
  width: 10rem;
}

.footer-social-links .navbar-link {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
}

.footer-social-links .navbar-link:not(.mobile-scroll-to-top-footer-button) {
  margin: 0 !important;
}

@media screen and (max-width: 992px) {
  .footer-logo .navbar-link {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .footer-copyright-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-social-links {
    justify-content: flex-start;
  }

  .mobile-scroll-to-top-footer-button {
    margin-top: 1rem !important;
  }
}
